(function ($) {

	"use strict";

	$('.item-page table').shadowScroller();
	$('.accessories.djc_list table').shadowScroller();
	$('.djc_support_detail table').shadowScroller();
	$('.djc_tabs table').shadowScroller();

	/* Tablet navigation */
	if($('.icons-toggle').length) {
		jQuery('.icons-toggle').on('touchstart click', function (e) {
			e.preventDefault();
			$(this).toggleClass('active');
			$(".mobile-navigation").toggleClass('active');
			$("#mobile-navigation-overlay").toggleClass('show');
			return false;
		});
		jQuery('#mobile-navigation-overlay').on('touchstart click', function (e) {
			e.preventDefault();
			jQuery('.icons-toggle').trigger('click');
		});
	}
	/* End of Tablet navigation */

	if($('.accordionmenu ul.menu').length) {
		$('.accordionmenu ul.menu').accordionMenu({expandClassname: 'active'});
	}

	if($('.item-page.hasLeft').length != 0 && $('.left .menu .level1.active').length == 0) {
		$('.item-page.hasLeft').removeClass('hasLeft');
		if($('.left .menu').length)
			$('.left .menu').remove();
	}

	/* Tab mobile */
	if($('.jwts_tabber').length || $('.jwts_tabberlive').length) {
		var wait = setInterval(function () {
			if($('.jwts_tabber').length) {
				return true;
				/* wait for tabs to init */
			} else {
				clearInterval(wait);
			}

			$('.jwts_tabberlive').each(function () {
				var desktopTabs = $(this);
				var mobileTabs = $('<div class="jwts_tabbermobile desktopHide"></div>');
				desktopTabs.addClass('desktopOnly');
				desktopTabs.find('.jwts_tabbernav li a').each(function (index) {
					var mobileTab = $('<div class="jwts_mobiletab"></div>');
					var mobileTab_heading = $('<div class="jwts_mobiletab-heading"></div>');
					var mobileTab_content = $('<div class="jwts_mobiletab-content"></div>');
					mobileTab_heading.text($(this).html());
					mobileTab_content.html($(desktopTabs.find('.jwts_tabbertab').get(index)).html());
					mobileTab_heading.on('click', function (e) {
						e.preventDefault();
						if(mobileTab.hasClass('active')) {
							mobileTab.removeClass('active');
							mobileTab.find('.jwts_mobiletab-content').slideUp(400);
							return true;
						}
						var activeTab = mobileTabs.find('.jwts_mobiletab.active');
						if(activeTab.length) {
							activeTab.removeClass('active');
							activeTab.find('.jwts_mobiletab-content').slideUp(400);
						}
						mobileTab.addClass('active');
						mobileTab_content.slideDown(400);
						setTimeout(function () {
							jQuery("html, body").animate({scrollTop: mobileTab.offset().top}, 400);
						}, 400);
					});
					mobileTab.append(mobileTab_heading);
					mobileTab.append(mobileTab_content);
					mobileTabs.append(mobileTab);
				});
				desktopTabs.after(mobileTabs);
			});
		}, 50);
	}
	/* Tab mobile */

	/* Back to top */
	var btt = jQuery('<a href="javascript:void(0);" id="back_to_top" class="btn red arrow">Back to top</a>');
	jQuery('#viewport').append(btt);
	btt.click(function () {
		jQuery("html, body").animate({scrollTop: 0}, 400);
	});
	var bttTimer = null;
	jQuery(window).scroll(function () {
		var bt = jQuery('#back_to_top');
		if(jQuery(this).scrollTop() > 500 && !bt.hasClass('shown')) {
			clearTimeout(bttTimer);
			bt.addClass('shown');
			bttTimer = setTimeout(function () {
				bt.removeClass('shown');
			}, 5000)
		}
	});
	/* Endof Back to top */

	/***clear support form when page loaded***/
	jQuery('.support-center #djcatalogForm select').val('');

	/*two column table resize for mobile*/
	if(jQuery(".content table ").length > 0) {
		var screensize = jQuery(window).width();
		if(screensize < 768) {
			var numCols = jQuery(".content table ").find('tr')[0].cells.length;
			if(numCols == 2) {
				jQuery(".content table ").css('width', '100%');
				jQuery(".content table th:first-child ").css('width', '30%');
				jQuery(".content table th:last-child ").css('width', '70%');
				jQuery(".content table").parent().next().next().css('opacity', '0');

			}
		}
	}


	/* investor submenu responsive click */
	var investorMenuBtn = jQuery('.investor-submenu li.level2');

	investorMenuBtn.on('click', function (e) {

		var screenWidth = jQuery(window).width();
		var currentMenu = jQuery(this);

		if(screenWidth < 1279) {
			if (!$(e.target).closest('a').length) {
				if(currentMenu.find(" > ul").css('display') == 'none') {
					investorMenuBtn.removeClass('open');
					investorMenuBtn.find(" > ul").css('display', 'none');
					currentMenu.find(" > ul").css('display', 'block');
					currentMenu.addClass('open');
				} else {
					currentMenu.find(" > ul").css('display', 'none');
					currentMenu.removeClass('open');
				}
			}
		}
		return true;

	});

})(jQuery);